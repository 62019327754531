<template>
    <div class="landing-footer">
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-auto">
                        <div class="category mb-4 mb-md-0">
                            <h2>Legal</h2>
                            <ul>
                                <li>
                                    <router-link :to="{ name: 'memod-tos' }">
                                        Terms of Service
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'memod-privacy-policy' }">
                                        Privacy Policy
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-auto">
                        <div class="category mb-4 mb-md-0">
                            <h2>Founding Creators Program</h2>
                            <ul>
                                <li>
                                    <router-link :to="{ name: 'founders-faq' }">
                                        FAQ
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'founders-tos' }">
                                        Program Terms of Service
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-auto ml-lg-auto">
                        <div class="subscribe-box">
                            <h2>Subscribe</h2>
                            <ValidationObserver v-slot="{ handleSubmit, reset }" slim>
                                <form
                                    class="footer-newsletter-form"
                                    novalidate
                                    @submit.prevent="handleSubmit(onSubmit)"
                                    @reset.prevent="reset"
                                >
                                    <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
                                        <div class="form-group" :class="{ 'required' : errors.length }">
                                            <input
                                                v-model.trim="email"
                                                type="email"
                                                name="email"
                                                class="form-control"
                                                placeholder="Email"
                                            >
                                            <button type="submit" class="btn btn-primary" name="subscribe">
                                                <img src="@assets/img/icons/send.svg" alt="Send">
                                            </button>
                                        </div>
                                        <span class="errors">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </form>
                            </validationObserver>
                            <span>No spam, notifications only about new products and updates.<br>You can always unsubscribe.</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div class="copyright-footer">
            <div class="container">
                <div class="copyright-left">
                    <img src="@assets/img/memod-icon-logo.svg" alt="Memod">
                    <span>&copy; {{ currentYear }} Memo'd Technologies, LLC. All rights reserved.</span>
                </div>
                <div class="copyright-right">
                    <p>If you like Memo'd, you'll love<br>our Book Insights podcast!</p>
                    <social-media-icons />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import newsletterMixin from "./newsletter-mixin";

export default {
    name: "LandingFooter",
    components: {
        SocialMediaIcons: () => import(/* webpackChunkName: "social-media-icons" */ "@c/molecules/social-media-icons")
    },
    mixins: [newsletterMixin],
    data() {
        return {
            baseUrl: window.location.origin
        };
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    },
    methods: {
        resetForm() {
            document.querySelector(".footer-newsletter-form").reset();
        }
    }
}
</script>

<style lang="scss" scoped>
.landing-footer {
    footer {
        padding: 70px 0;
        background-color: #1C1C1E;

        .category {
            margin-right: 50px;

            h2 {
                color: #f7f7f7;
                margin-bottom: 12px;
            }

            ul {
                list-style: none;
                padding-left: 0;
                margin-bottom: 0;

                li {
                    a {
                        color: #b5b5b5;
                        font-size: 18px;
                        line-height: 22px;
                        text-decoration: none;
                        display: block;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .subscribe-box {
            width: 320px;

            h2 {
                color: #f7f7f7;
                margin-bottom: 20px;
            }

            form {
                .form-group {
                    display: flex;
                    margin-bottom: 20px;

                    .form-control {
                        border: 1px solid #424242;
                        border-radius: 6px;
                        padding: 5px 0;
                        background-color: #282828;
                        font-size: 15px;
                        color: #B5B5B5;
                        font-weight: 400;
                        padding-left: 10px;
                    }

                    .btn {
                        padding: 0;
                        width: 38px;
                        border-radius: 6px;
                        margin-left: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            margin-left: 2px;
                        }
                    }

                    &.required {
                        margin-bottom: 10px;

                        & + .errors {
                            color: $error-color;
                            display: block;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            span {
                font-size: 12px;
                color: #6c6c6c;
                font-weight: 400;
            }
        }
    }

    .copyright-footer {
        padding: 30px 0;
        background-color: #2C2C2E;

        > .container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media(max-width: $lg) {
                flex-direction: column;

                .copyright-right {
                    margin-top: 20px;
                }
            }
        }

        .copyright-left {
            display: flex;
            align-items: center;

            span {
                @extend .body-text;
                color: #f7f7f7;
                display: block;
                margin-left: 20px;
            }
        }

        .copyright-right {
            background-color: rgba(58, 58, 60, 0.6);
            padding: 4px 8px;
            display: flex;
            align-items: center;
            border-radius: 8px;

            p {
                margin-bottom: 0;
                margin-right: 20px;
            }

            @media(max-width: $sm) {
                flex-direction: column;
                text-align: center;

                p {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
</style>
