export default {
    data() {
        return {
            email: "",
            mailchimp_list_id: process.env.VUE_APP_MAILCHIMP_LIST_ID
        };
    },
    methods: {
        onSubmit() {
            axiosPublic({
                url: `/newsletter`,
                method: "POST",
                data: {
                    email: this.email,
                    list_id: this.mailchimp_list_id
                }
            }).then(() => {
                this.resetForm();
                this.email = "";
                this.$notify({
                    group: "app-notifications",
                    type: "success",
                    text: "Thank you for subscribing!.",
                    duration: 5000
                });
            }).catch(() => {
                this.$notify({
                    group: "app-notifications",
                    type: "error",
                    text: "Something went wrong!.",
                    duration: 5000
                });
            }).finally(() => {
                this.isLoading = false;
            })
        }
    }
}
